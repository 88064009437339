<template>
    <div>
        <Header />
        <Breadcrumbs title="Faq" />
        <section class="faq-section section-b-space">
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  <h3>BIENVENUE SUR LA PAGE D'AIDE D'ASCENSION GROUP</h3>
                  <h6>
                    Cliquer sur la phrase qui correspond à votre problème ou préoccupation.
                  </h6>
                </div>
              </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="accordion theme-accordion" id="accordionExample">
                            <b-card no-body class="mb-1" v-for="(item, index) in items" :key="index">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block href="#" v-b-toggle="'accordion-' + index" variant="info">{{item.question}}</b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <p v-html="item.reponse"></p>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
    import Header from '../../components/header/appHeader'
    import Footer from '../../components/footer/appFooter'
    import Breadcrumbs from '../../components/widgets/breadcrumbs'

    import axios from 'axios'

    export default {
        name: 'faq',

        components: {
            Header,
            Footer,
            Breadcrumbs
        },

        data() {
            return {
                items: []
            }
        },

      methods: {
          getFaqs(){
            axios.get('faqs')
              .then(({data}) => {
                if(data.success){
                  this.items = data.data
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
      },

      mounted() {
          this.getFaqs()
      }
    }
</script>
